// About.js
import React from 'react';
import headshot from '../assets/headshot.jpg';  // Adjust path as necessary
import './About.css';  // Assuming you have CSS for styling

function About() {
    return (
        <div className="About">
            <div className="About-content">
                <img src={headshot}
                alt="Zizo Bahnasy"
                className="About-photo"
                style={{ width: '420px', height: '420px' }} />
                <div className="About-description">
                    <h1>Welcome to Project Housing!</h1>
                    <p>My name is Zizo Bahnasy, and I'm the founder of Project Housing. Millions of Americans are struggling to find affordable housing as prices go up and wages remain stagnant, and our mission is to make it possible for families, young people, and those dealing with trying times to find a home. If you're on this page, you've probably realized that it's really hard to find out which affordable housing opportunities are open and how to apply to them, so our goal is to create a central portal that shows users exactly which units are in stock in real-time. We are looking to partner with local municipalities, administrative placement agents, developers, and the US Department of Housing and Urban Development to make sure that you have the information you need in order to get housing. There's good news: states like New Jersey (where we're starting) are ramping up affordable housing requirements in order to fill housing shortages on the order of hundreds of thousands of units, which means there's cause to be hopeful for the years ahead. We're deeply invested in making this hope as accessible as possible to everybody. Please reach out to me at <a href="mailto:zizo.bahnasy@gmail.com">zizo.bahnasy@gmail.com</a> if you have any questions or if you would like to support us!</p>
                </div>
            </div>
        </div>
    );
}

export default About;
