// App.js
import React from 'react';
import { Widget } from '@typeform/embed-react';
import './App.css';
import { Routes, Route, useNavigate } from "react-router-dom";
import About from "./routes/About";
import Navbar from './Navbar';
import { ReactComponent as Logo } from './assets/logo.svg';

function App() {
  const navigate = useNavigate();

  const navigateHome = () => {
    navigate('/'); // Navigate to the root path
  };

  return (
    <div className="App">
      <header className="App-header">
        <Logo className="App-logo" alt="Project Housing Logo" onClick={navigateHome} style={{cursor: 'pointer'}} />
      </header>
      <main className="App-main">
        <Routes>
          <Route path="/" element={<Widget id="mvqj6fPx" style={{ width: '100%', height: '500px' }} />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </main>
      <Navbar />
    </div>
  );
}
export default App;
