// Navbar.js
import React from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import './Navbar.css'; // Ensure you have this file for styles

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleAboutClick = () => {
    // Toggle between About page and Home
    if (location.pathname === '/about') {
      navigate('/');
    } else {
      navigate('/about');
    }
  };

  return (
    <div>
      <button onClick={handleAboutClick} className="navy-button">About Us</button>
    </div>
  );
};

export default Navbar;
